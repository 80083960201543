import axios from "axios";
import { toastr } from "react-redux-toastr";
import * as Utils from "./";
import history from "../history";
import localization from "../utils/localization";

const apiPrefix = "admin/";

class ApiService {
  constructor() {
    var headers = {
      Authorization: "Bearer " + Utils.getToken(),
      "Content-Type": "application/json"
    };

    const updatedService = axios.create({
      headers: headers
    });

    updatedService.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
    this.service = updatedService;
  }

  updateService() {
    const updatedService = axios.create({
      headers: {
        Authorization: "Bearer " + Utils.getToken(),
        "Content-Type": "application/json"
      }
    });

    updatedService.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
    this.service = updatedService;
  }

  redirectToLogin() {
    Utils.removeLoginData();
    toastr.error(
      localization.toast.error,
      localization.apiErrors.notAuthenticated
    );
    history.push("/login");
  }

  handleSuccess(response) {
    return response;
  }

  handleError = error => {
    if (!error) {
      toastr.error(
        localization.apiErrors.networkError,
        localization.apiErrors.noServerResponse
      );
    }

    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          this.redirectToLogin();
          return Promise.reject(null);
        case 403:
          console.error("Error, not allowed!", error.message);
          break;
        case 404:
          break;
        case 422:
          if (error) {
            if (error.response) {
              if (error.response.data.error && !error.response.data.success) {
                error.message = error.response.data.error;
              }
            }
          }
          break;
        default:
          break;
      }
    }

    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get(path = "") {
    this.updateService();
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .get(`${process.env.REACT_APP_API_URL}${apiPrefix}${path}`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  patch(path, payload) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .request({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}${apiPrefix}${path}`,
          responseType: "json",
          data: payload
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  post(path, payload) {
    const self = this;

    return new Promise(function(resolve, reject) {
      self.service
        .request({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}${apiPrefix}${path}`,
          responseType: "json",
          data: payload
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  postMultipart(path, data) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .request({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL_v2}${apiPrefix}${path}`,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          data: data
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  delete(path) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .delete(`${process.env.REACT_APP_API_URL}${apiPrefix}${path}`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  getApiVersion() {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .get(`${process.env.REACT_APP_API_URL}`)
        .then(response => resolve(response.data.data.version_number))
        .catch(error => reject(error));
    });
  }

  /*
   **  AUTHENTICATION
   */

  createUser(username, password) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .request({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}createUser`,
          responseType: "json",
          data: {
            email: username,
            password: password
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  login = async (username, password) => {
    const self = this;
    try {
      const response = await self.service.request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}auth`,
        responseType: "json",
        data: {
          username: username,
          password: password
        }
      });

      return { success: true, data: response.data };
    } catch (err) {
      return { success: false, error: err };
    }
  };

  logout() {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.service
        .request({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}logout`,
          responseType: "json",
          data: {
            token: Utils.getToken()
          }
        })
        .then(response => {
          Utils.removeLoginData();
          resolve(response);
        })
        .catch(error => reject(error));
    });
  }

  isLoggedIn() {
    const self = this;
    const token = Utils.getToken();

    return new Promise(function(resolve, reject) {
      if (token) {
        self.service
          .get(`${process.env.REACT_APP_API_URL}isLoggedIn`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      } else {
        reject();
        Utils.removeLoginData();
      }
    });
  }

  resetPassword(email = "") {
    const self = this;
    return new Promise(function(resolve, reject) {
      if (email !== "") {
        self.service
          .get(
            `${
              process.env.REACT_APP_API_URL
            }resetPassword/${email}?adminPanel=true`
          )
          .then(response => resolve(response))
          .catch(error => reject(error));
      } else {
        reject("Please insert valid email.");
      }
    });
  }

  isResetPasswordIdValid(id = "") {
    const self = this;
    return new Promise(function(resolve, reject) {
      if (id !== "") {
        self.service
          .get(`${process.env.REACT_APP_API_URL}isResetPasswordIdValid/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      } else {
        reject();
      }
    });
  }

  updateUserPassword(user, resetId) {
    const self = this;
    return new Promise(function(resolve, reject) {
      if (user && user._id && user._id !== "") {
        self.service
          .patch(
            `${process.env.REACT_APP_API_URL}updateUserPassword/${resetId}`,
            user
          )
          .then(response => resolve(response))
          .catch(error => reject(error));
      }
    });
  }
}

export default new ApiService();
